import React from 'react';
//import logo from './logo.svg';
import './App.css';
import Router from "./Router";
import {BrowserRouter} from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";


function App() {
  return (
      <>
          <Header />
          <div className={'header-spase'}></div>
          <BrowserRouter>
              <Router />
          </BrowserRouter>
          <Footer />
      </>
  );
}

export default App;


import React from "react";
import logo from './assets/logp_DC.png';
const Header: React.FC = () => {

    return(
        <>
            <div className={'ft-header'}>
                {/*
                <div className={'ft-header--top'}>
                    <div className={'text-center'}>
                        <a href="tel:+18553732683">1-855-373-2683</a>
                    </div>
                </div>
                */}
                <div className={'ft-header--center'}>
                    <div className={'ft-header--center--inner'}>
                        <div style={{alignItems: 'center', display: 'grid'}}>
                            <div className={' header-contact text-center'}>
                                <a className={'ft-contact-a'} href="tel:+18553732683">1-855-373-2683</a>
                            </div>
                        </div>

                        <div className={'header-menu desktop-only'}>
                            <div><a className={'ft-certified'} target={'_blank'} href="https://dreamcatchers.com/pages/4-in-1-certification"  rel="noreferrer">GET CERTIFIED</a></div>
                            <div><a target={'_blank'} href="https://dreamcatchers.com/collections/all-products" rel="noreferrer">SHOP</a></div>
                            <div><a target={'_blank'} href="https://dreamcatchers.com/pages/dreamcatchers" rel="noreferrer">ABOUT THE HAIR</a></div>
                            <div >
                                <a href="/" className={"logo-link"}>
                                    <img src={logo} alt=""/>
                                </a>
                            </div>
                            <div><a target={'_blank'} href="https://education.dreamcatchers.com/" rel="noreferrer">EDUCATION HUB</a></div>
                            <div><a target={'_blank'} href="https://dreamcatchers.com/pages/yotpo-reviews" rel="noreferrer">REVIEWS</a></div>
                            <div><a target={'_blank'} href="https://dreamcatchers.com/blogs/dreamcatchers-hair-blog" rel="noreferrer">BLOG</a></div>
                        </div>
                        <div className={'header-menu mobile-only'}>

                            <div>
                                <a href="/" className={"logo-link"}>
                                    <img src={logo} alt=""/>
                                </a>
                            </div>
                        </div>

                        <div></div>
                    </div>
                </div>
                {/*
                <div className={'ft-header--top'}>
                    <div className={'text-center'}>
                        <a href="tel:+18553732683">CONTACT US: 1-855-373-2683</a>
                    </div>
                </div>
                <div className={'ft-header--center'}>
                    <div className={'ft-header--center--inner'}>
                        <div className={'ft-menu-part'}>
                            <div className={'left-menu'}>
                                <div><a href="https://dreamcatchers.com/pages/dreamcatchers">ABOUT THE HAIR</a></div>
                                <div><a href="https://dreamcatchers.com/pages/4-in-1-certification">GET CERTIFIED</a></div>
                                <div><a href="https://dreamcatchers.com/collections/all-products">SHOP</a></div>
                            </div>
                        </div>
                        <div className={'ft-logo-block'}>
                            <a href="/">
                                <img src={logo} alt="logo"/>
                            </a>
                        </div>
                        <div className={'ft-menu-part'}>
                            <div className={'right-menu'}>
                                <div><a href="https://education.dreamcatchers.com/">EDUCATION HUB</a></div>
                                <div><a href="https://dreamcatchers.com/pages/yotpo-reviews">REVIEWS</a></div>
                                <div><a href="https://dreamcatchers.com/blogs/dreamcatchers-hair-blog">BLOG</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                */}
            </div>
        </>
    )
}
export default Header;
// routes.tsx

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from "./pages/home/Home";
import FormPage from "./pages/form-page/FormPage";


export default function Router() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/application" element={<FormPage />} />
                <Route path="*" element={<Home />} />
                {/*
                <Route path="*" element={<Home />} />
            <Route path="/about" component={About} />
            <Route component={NotFound} />
            */}
            </Routes>
        </>
    );
};



// src/pages/home/Home.tsx

import React, {useState} from 'react';
import classes from './home.module.scss'
import img2 from './assets/home-img-2.png';
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import {Autoplay, Navigation} from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import j1 from './assets/j1.png'
import j2 from './assets/j2.png'
import j3 from './assets/j3.png'
import j4 from './assets/j4.png'
import j5 from './assets/j5.png'
import j6 from './assets/j6.png'
import img_price from './assets/price.png'
import img_price2 from './assets/price2.png'
import CountdownTimer from "../../components/CountdownTimer/CountdownTimer";


const Home: React.FC = () => {


    const [sliderItems] = useState([
        {
            image: j1,
            title: 'DOROTHY WRIGHTSMAN',
            text: 'San Diego Salon Owner & DC Educator'
        },
        {
            image:  j2,
            title: 'MARLYS MENDEZ',
            text: '17 Year Industry Veteran & Head Educator'
        },
        {
            image: j3,
            title: 'RUBEN VARGAS',
            text: 'Hair Stylist For Over 10 Years Specializing In All Forms Of Color'
        },
        {
            image: j4,
            title: 'JORDAN YANATOS',
            text: 'Corrective Color Queen! Specializes In Vivid Color & Extensions'
        },
        {
            image: j5,
            title: 'GERILYN GHAISARZADEH',
            text: 'Passionate About Creative Color And Education'
        },
        {
            image: j6,
            title: 'JESSICA POWERS',
            text: 'Versatile Stylist & Salon Owner Known For Her Blending Techniques And Vivid Color Work'
        },
    ]);


    return (
        <>

            <div className={'ft-container ft-section-margin-top'}>
                <CountdownTimer />
            </div>

            <div className={'ft-front-back-header ft-front-back-header--576167'}>
                <div className={'ft-back-font'}>STRANDS</div>
                <div className={'ft-front-font'}>Battle of the Strands</div>
            </div>

            <div className={'ft-container ft-section-margin-bottom'}>
                 <div className={'header-h3 text-center'}>
                    Due to the high volume of submissions and some stylists experiencing difficulty uploading their transformations, we're extending the closing date to ensure everyone has a fair chance!
                </div>
            </div>


            <div className={'ft-container'}>
                <div className={'video-container'}>
                    <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                        <iframe
                            src="https://player.vimeo.com/video/941575964?autoplay=1&muted=1&loop=1&title=0&byline=0&portrait=0&badge=0"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
                            title="BTS Stylist circle"
                        ></iframe>
                    </div>
                    {/*
                    <video playsInline autoPlay loop controls muted preload="metadata"
                           poster="//nsghair.com/cdn/shop/files/preview_images/1458b998e1de4346945e9e91ee3f2f3a.thumbnail.0000000000_1100x.jpg?v=1699675124">
                        <source
                            src="https://cdn.shopify.com/videos/c/vp/1458b998e1de4346945e9e91ee3f2f3a/1458b998e1de4346945e9e91ee3f2f3a.HD-720p-3.0Mbps-20541435.mp4"
                            type="video/mp4" />
                    </video>
                    */}
                </div>
            </div>

            <div className={'ft-container ft-section-margin'}>
                <div className={'header-h2 text-center'}>
                    Submit your <span className={'fr-color-1'}>Best Work</span> to our <span className={'fr-color-1'}>Biggest<br className={'desktop-only'} /> Competition</span> yet and have the chance to <span className={'fr-color-1'}>WIN BIG!</span>
                </div>
            </div>

            <div className={'ft-container ft-section-margin text-center'}>
                <a className="ft-button" target={'_blank'} rel="noreferrer" href={'https://www.facebook.com/groups/dreamcatchershair/'}>
                    Join the stylist circle now
                </a>
            </div>

            <div className={'ft-front-back-header ft-front-back-header--564537'}>
                <div className={'ft-back-font'}>JUDGES</div>
                <div className={'ft-front-font'}>MEET THE JUDGES</div>
            </div>

            <div className={'ft-container ft-container-p0'}>
                <div className={classes.slider1}>
                    <Swiper
                        navigation={true}
                        modules={[Navigation, Autoplay]}
                        loop={true}
                        className="Swiper Swiper-judges"
                        autoplay={{ delay: 5000 }}
                        speed={1000} // Установить скорость перехода в 1000 миллисекунд (1 секунда)
                    >
                        {sliderItems.map((item, index) => (
                            <SwiperSlide key={index}>
                               <div className={classes.sliderItem}>
                                   <div className={classes.sliderItemInner}>
                                       <div className={classes.imageBlock}>
                                           <div className={classes.imageBlockInner}>
                                               <img src={item.image} alt={item.title} />
                                           </div>
                                       </div>
                                       <div className={classes.textBlock}>
                                           <div>
                                               <div className={classes.title}>{item.title}</div>
                                               <div className={classes.text}>{item.text}</div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                            </SwiperSlide>
                        ))}

                    </Swiper>
                </div>
            </div>



            <div  style={{background:"var(--fr-color-2)"}}>
                <div className={'ft-container ft-section-margin-top ' + classes.prizesContainer}>
                    <div className={classes.row1}>
                        <div className={classes.k1}>
                            win these
                        </div>
                        <div className={classes.k2}>
                            amazing
                        </div>
                        <div className={classes.k3}>
                            prizes!
                        </div>
                    </div>
                </div>
            </div>
            <div className={'ft-container ' + classes.prizesContainer2}>
                <div className={classes.row2}>
                    <div className={classes.prizesList}>
                        <div className={classes.block + ' ' + classes.block1}>
                            <div className={classes.b1}>
                                <div className={classes.t}>
                                    1ST
                                </div>
                                <div className={classes.r}>
                                    PRIZE
                                </div>
                            </div>
                            <div className={classes.b2}>
                                <div className={classes.s}>
                                    $20,000
                                </div>
                            </div>
                        </div>
                        <div className={classes.block + ' ' + classes.block2}>
                            <div className={classes.b1}>
                                <div className={classes.t}>
                                    2ND
                                </div>
                                <div className={classes.r}>
                                    PRIZE
                                </div>
                            </div>
                            <div className={classes.b2}>
                                <div className={classes.s}>
                                    $10,000
                                </div>
                            </div>
                        </div>
                        <div className={classes.block + ' ' + classes.block3}>
                            <div className={classes.b1}>
                                <div className={classes.t}>
                                    3RD
                                </div>
                                <div className={classes.r}>
                                    PRIZE
                                </div>
                            </div>
                            <div className={classes.b2}>
                                <div className={classes.s}>
                                    $5,000
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'desktop-only ' + classes.imageBlock}>
                        <img src={img_price} alt=""/>
                    </div>
                </div>
            </div>



            <div className={'ft-container ft-section-margin'}>
                <div className={'header-h3 text-center'}>
                    Please note: DC will be granted a license to utilize all footage for marketing and promotional purposes as well as will be entitled a detailed interview from the winners.
                </div>
            </div>

            <div className={'ft-container ft-section-margin-top text-center'}>
                <a className="ft-button" href={'/application'}>
                    Submit Your Entry Now
                </a>
            </div>

            <div className={'ft-section-margin-top mobile-only'}>
                <div className={'ft-section-margin'}>
                    <img src={img_price2} alt=""/>
                </div>
            </div>








            {/*
            <div className={'ft-container ft-section-margin'}>
                <div className={'video-container'}>
                    <video playsInline autoPlay loop controls muted preload="metadata"
                           poster="//nsghair.com/cdn/shop/files/preview_images/1458b998e1de4346945e9e91ee3f2f3a.thumbnail.0000000000_1100x.jpg?v=1699675124">
                        <source
                            src="https://cdn.shopify.com/videos/c/vp/1458b998e1de4346945e9e91ee3f2f3a/1458b998e1de4346945e9e91ee3f2f3a.HD-720p-3.0Mbps-20541435.mp4"
                            type="video/mp4" />
                    </video>
                </div>
            </div>
            */}

            <div className={'ft-front-back-header ft-front-back-header--5857367'}>
                <div className={'ft-back-font'}>CONTEST</div>
                <div className={'ft-front-font'}>What is the contest about?</div>
            </div>

            <div className={'ft-container ft-section-margin-bottom'}>
                <div className={'header-h2 text-center'}>
                    The Battle of the Strands: Where stylists compete to shine and stand out. <span className={'fr-color-1'}>High stakes, higher talent levels.</span>
                </div>
            </div>

            <div className={'ft-container ft-section-margin text-center'}>
                <div className={classes.block343}>
                    <div className={classes.t1}>Submit your videos between</div>
                    <div className={classes.t2}>March 1st - May 10th</div>
                    <div className={classes.t3}>submissions close <span style={{whiteSpace:"nowrap"}}>at 12pm cst</span></div>
                    <div className={classes.t4}>for the chance to win our biggest prize yet!</div>
                </div>
            </div>
            <div className={'ft-container ft-section-margin text-center'}>
                <div className={classes.block3433}>
                    <div className={classes.t5}>
                    winners will be announced that <span className={'fr-color-1'}>evening on the webinar.</span>
                    </div>
                </div>
            </div>



            <div className={'ft-section-margin'}>
                <img src={img2} alt=""/>
            </div>




            <div className={'ft-front-back-header ft-front-back-header--55462167'}>
                <div className={'ft-back-font'}>WORKS</div>
                <div className={'ft-front-font'}>How it works?</div>
            </div>


            <div className={'ft-container ft-section-margin-bottom ' + classes.worksContainer}>
                <div className={classes.worksContainerInner}>
                    <div className={classes.item}>
                        You <span className={'fr-color-1'}>MUST</span> be a part of the stylist circle.
                    </div>
                    <div className={classes.item}>
                        Create a <span className={'fr-color-1'}>30-90 seconds Hair Transformation Video exclusively featuring DC products & tools</span>, then share it on the stylist circle and instagram.
                    </div>
                    <div className={classes.item}>
                        Tag <span className={'fr-color-1'}>@dreamcatchershair</span> and <span className={'fr-color-1'}>#DCBattleoftheStrands</span>
                    </div>
                    <div className={classes.item}>
                        All submissions will be judged by our panel of judges & winners will be announced on <span className={'fr-color-1'}>May 13, 2024</span>.
                    </div>
                </div>
            </div>


            <div className={'ft-front-back-header ft-front-back-header--5614377'}>
                <div className={'ft-back-font'}>LEADERBOARDS</div>
                <div className={'ft-front-font'}>Leaderboard List</div>
            </div>



            <div className={'ft-container ft-section-margin-bottom'}>
                <div className={'header-h3 text-center'}>
                    COMING SOON...
                </div>
            </div>



        </>
    );
};

export default Home;

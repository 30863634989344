// src/pages/home/FormPage.tsx

import React from 'react';
//import styles from './FormPage.module.scss';
//import img1 from './assets/form-page-1.png'

const FormPage: React.FC = () => {



    return (
        <>


            <div className={'ft-front-back-header ft-front-back-header--567'}>
                <div className={'ft-back-font'}>COMPETITION</div>
                <div className={'ft-front-font'}>JOIN THE COMPETITION</div>
            </div>

            {/*
            <div className={''}>
                <img src={img1} alt=""/>
            </div>
*/}
            <div className={'ft-container ft-section-margin-bottom ft-front-back-header ft-front-back-header--42342'}>
                <div className={'ft-title-093450'}>GET THE CHANCE TO BE DISCOVERED<br/>AND WIN ENORMOUS PRIZES!</div>
            </div>


            <div className={'ft-container ft-section-margin'}>
                <div className={'video-container'}>
                    <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                        <iframe
                            src="https://player.vimeo.com/video/918597580?autoplay=1&muted=1&loop=1&title=0&byline=0&portrait=0&badge=0"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
                            title="BTS Stylist circle"
                        ></iframe>
                    </div>
                    {/*
                    <video playsInline autoPlay loop controls muted preload="metadata"
                           poster="//nsghair.com/cdn/shop/files/preview_images/1458b998e1de4346945e9e91ee3f2f3a.thumbnail.0000000000_1100x.jpg?v=1699675124">
                        <source
                            src="https://cdn.shopify.com/videos/c/vp/1458b998e1de4346945e9e91ee3f2f3a/1458b998e1de4346945e9e91ee3f2f3a.HD-720p-3.0Mbps-20541435.mp4"
                            type="video/mp4" />
                    </video>
                    */}
                </div>
            </div>



            <div className={'ft-container ft-section-margin ft-woobox-form'}>

                {/*
                <div className="woobox-offer" data-offer="qbo5df" data-trigger="enter"></div>
                */}
                <div className="woobox-offer" data-offer="fp49yd" data-trigger="enter"></div>

            </div>


        </>
    );
};

export default FormPage;

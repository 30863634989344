import React, { useState, useEffect } from 'react';
import classes from './CountdownTimer.module.scss';

const CountdownTimer = () => {

    interface TimeLeft {
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
    }
    const calculateTimeLeft = (): TimeLeft => {
        const targetDate = new Date('May 10, 2024 12:00:00 CST');
        const difference = +targetDate - +new Date();
        let timeLeft: TimeLeft = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };


    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const formatTime = (time: number): string => {
        return time < 10 ? `0${time}` : time.toString();
    };


    return (
        <div className={classes.countdown}>
            <div className={classes.countdownInner}>


                <div className={classes.item}>
                    <div className={classes.number}>
                        {formatTime(timeLeft.days)}
                    </div>
                    <div className={classes.label}>
                        DAYS
                    </div>
                </div>
                <div className={classes.item}>
                    <div className={classes.number}>
                        {formatTime(timeLeft.hours)}
                    </div>
                    <div className={classes.label}>
                        HOURS
                    </div>
                </div>
                <div className={classes.item}>
                    <div className={classes.number}>
                        {formatTime(timeLeft.minutes)}
                    </div>
                    <div className={classes.label}>
                        MINS
                    </div>
                </div>
                <div className={classes.item}>
                    <div className={classes.number}>
                        {formatTime(timeLeft.seconds)}
                    </div>
                    <div className={classes.label}>
                        SECS
                    </div>
                </div>

            </div>
        </div>
    );
};

export default CountdownTimer;


import React from "react";
//import logo from "../header/assets/logp_DC.png";
const Footer: React.FC = () => {

    return(
        <>
            <div className={'ft-footer site-footer'}>
                <div className={'ft-container text-center'}>
                    <a href="/" className={"logo-link footer-logo-link"}>
                        <img src={'https://dreamcatchers.com/cdn/shop/files/logo-nav-blak-pink_x60@2x.png?v=1613183050'} alt=""/>
                    </a>
                </div>
                <div className={'ft-container ft-after'}>
                    <div className={'footer__collapsible footer_collapsible--disabled'}>
                        <ul className="no-bullets site-footer__linklist footer-menu-custom">

                        <li><a target={'_blank'} rel="noreferrer" href="https://dreamcatchers.com/collections/all-products">SHOP</a>

                            <ul className="child">

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/pages/4-in-1-certification">Online Certification</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/collections/i-tip">I-Tip Hair Extensions</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/collections/tape-in">Tape In Hair Extensions</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/collections/weft">Weft Hair Extensions</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/collections/k-tip">K-Tip Hair Extensions</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/products/crown-n-glory">Crown-N-Glory Hair Extensions</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/collections/accessories">Accessories</a>

                                </li>

                            </ul>

                        </li>

                        <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/pages/dreamcatchers">THE HAIR</a>

                            <ul className="child">

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/pages/color-styles">Colors &amp; Styles</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/pages/dreamcatchers">About DreamCatchers</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/pages/production-process">Production Process</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/pages/dc-media-library">Media Library</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/pages/yotpo-reviews">Reviews</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/blogs/dreamcatchers-hair-blog/">Blog</a>

                                </li>

                            </ul>

                        </li>

                        <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/pages/become-certified">GET CERTIFIED</a>

                            <ul className="child">

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/pages/sign-up">Become Certified</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/collections/hands-on-trainings">Hands-On Trainings</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/pages/course">About The Certification</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/apps/help-center">FAQ's</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/pages/sign-up">Contact Us</a>

                                </li>

                            </ul>

                        </li>

                        <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/pages/warranty-returns-exchanges">TERMS OF SERVICE</a>

                            <ul className="child">

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/policies/terms-of-service">Terms of Service</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/pages/warranty-returns-exchanges">Warranty &amp; Returns</a>

                                    <ul className="grandchild">

                                        <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/apps/redo/returns-portal">Start a Return,
                                            Exchange, or Warranty Claim</a></li>

                                    </ul>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/pages/shipping-delivery">Shipping &amp; Delivery</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/policies/refund-policy">Refund policy</a>

                                </li>

                                <li><a target={'_blank'} rel="noreferrer"  href="https://dreamcatchers.com/pages/privacy-policy">Privacy Policy</a>

                                </li>

                            </ul>

                        </li>

                    </ul>
                    </div>
                </div>

                <div className={'ft-container'}>
                    <ul className="no-bullets footer__social">

                        <li>
                            <a href="https://www.facebook.com/DreamCatchersHair/" target="_blank"  rel="noreferrer"
                               title="sm-dream on Facebook">
                                <svg aria-hidden="true" focusable="false" role="presentation" className="icon icon-facebook"
                                     viewBox="0 0 32 32">
                                    <path fill="#444"
                                          d="M18.56 31.36V17.28h4.48l.64-5.12h-5.12v-3.2c0-1.28.64-2.56 2.56-2.56h2.56V1.28H19.2c-3.84 0-7.04 2.56-7.04 7.04v3.84H7.68v5.12h4.48v14.08h6.4z"></path>
                                </svg>
                                <span className="icon__fallback-text">Facebook</span>
                            </a>
                        </li>

                        <li>
                            <a href="https://www.youtube.com/channel/UC9x1egq6PNyY2wI1lvkqkvA" target="_blank"  rel="noreferrer"
                               title="sm-dream on YouTube">
                                <svg aria-hidden="true" focusable="false" role="presentation" className="icon icon-youtube"
                                     viewBox="0 0 21 20">
                                    <path fill="#444"
                                          d="M-.196 15.803q0 1.23.812 2.092t1.977.861h14.946q1.165 0 1.977-.861t.812-2.092V3.909q0-1.23-.82-2.116T17.539.907H2.593q-1.148 0-1.969.886t-.82 2.116v11.894zm7.465-2.149V6.058q0-.115.066-.18.049-.016.082-.016l.082.016 7.153 3.806q.066.066.066.164 0 .066-.066.131l-7.153 3.806q-.033.033-.066.033-.066 0-.098-.033-.066-.066-.066-.131z"></path>
                                </svg>
                                <span className="icon__fallback-text">YouTube</span>
                            </a>
                        </li>

                        <li>
                            <a href="https://www.instagram.com/dreamcatchershair/" target="_blank"  rel="noreferrer"
                               title="sm-dream on Instagram">
                                <svg aria-hidden="true" focusable="false" role="presentation"
                                     className="icon icon-instagram" viewBox="0 0 32 32">
                                    <path fill="#444"
                                          d="M16 3.094c4.206 0 4.7.019 6.363.094 1.538.069 2.369.325 2.925.544.738.287 1.262.625 1.813 1.175s.894 1.075 1.175 1.813c.212.556.475 1.387.544 2.925.075 1.662.094 2.156.094 6.363s-.019 4.7-.094 6.363c-.069 1.538-.325 2.369-.544 2.925-.288.738-.625 1.262-1.175 1.813s-1.075.894-1.813 1.175c-.556.212-1.387.475-2.925.544-1.663.075-2.156.094-6.363.094s-4.7-.019-6.363-.094c-1.537-.069-2.369-.325-2.925-.544-.737-.288-1.263-.625-1.813-1.175s-.894-1.075-1.175-1.813c-.212-.556-.475-1.387-.544-2.925-.075-1.663-.094-2.156-.094-6.363s.019-4.7.094-6.363c.069-1.537.325-2.369.544-2.925.287-.737.625-1.263 1.175-1.813s1.075-.894 1.813-1.175c.556-.212 1.388-.475 2.925-.544 1.662-.081 2.156-.094 6.363-.094zm0-2.838c-4.275 0-4.813.019-6.494.094-1.675.075-2.819.344-3.819.731-1.037.4-1.913.944-2.788 1.819S1.486 4.656 1.08 5.688c-.387 1-.656 2.144-.731 3.825-.075 1.675-.094 2.213-.094 6.488s.019 4.813.094 6.494c.075 1.675.344 2.819.731 3.825.4 1.038.944 1.913 1.819 2.788s1.756 1.413 2.788 1.819c1 .387 2.144.656 3.825.731s2.213.094 6.494.094 4.813-.019 6.494-.094c1.675-.075 2.819-.344 3.825-.731 1.038-.4 1.913-.944 2.788-1.819s1.413-1.756 1.819-2.788c.387-1 .656-2.144.731-3.825s.094-2.212.094-6.494-.019-4.813-.094-6.494c-.075-1.675-.344-2.819-.731-3.825-.4-1.038-.944-1.913-1.819-2.788s-1.756-1.413-2.788-1.819c-1-.387-2.144-.656-3.825-.731C20.812.275 20.275.256 16 .256z"></path>
                                    <path fill="#444"
                                          d="M16 7.912a8.088 8.088 0 0 0 0 16.175c4.463 0 8.087-3.625 8.087-8.088s-3.625-8.088-8.088-8.088zm0 13.338a5.25 5.25 0 1 1 0-10.5 5.25 5.25 0 1 1 0 10.5zM26.294 7.594a1.887 1.887 0 1 1-3.774.002 1.887 1.887 0 0 1 3.774-.003z"></path>
                                </svg>
                                <span className="icon__fallback-text">Instagram</span>
                            </a>
                        </li>

                        <li>
                            <a href="https://www.tiktok.com/@dreamcatchershair" target="_blank"  rel="noreferrer"
                               title="sm-dream on Instagram">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                     className="icon bi bi-tiktok" viewBox="0 0 17 17">
                                    <path fill="#444"
                                        d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z"></path>
                                </svg>
                                <span className="icon__fallback-text">TikTok</span>
                            </a>
                        </li>

                    </ul>
                </div>

                <div>
                    <div className={'ft-container'}>
                        <p className="footer__small-text phone-footer">Call us <b>1-855-DREAM-TEAM</b></p>
                    </div>
                </div>

                <div style={{background:'#f7edf1', color: '#a95577', marginTop: '50px'}}>
                    <div className={'ft-container'}>
                        <div className={'copyright'}>
                            Copyright DreamCatchers All Rights Reserved © 2022
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer;